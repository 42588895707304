<main class="row container mx-auto">
 <section class="mx-auto col-lg-10 p-5">
    
    <h3 class="text-center">
            Male Infertility
        </h3>
        <p>
            Up to 15 percent of couples are infertile. This means they aren't able to conceive a child, even though theyâ€™ve had frequent, unprotected sexual intercourse for a year or longer. In over a third of these couples, male infertility plays a role.
            Literally, cancer can affect virually any structure of the body, but worthy of our attention is cancer of the prostate, which is the most common cancer among men worldwide.
        </p>
        <button>Talk to a Doctor</button>
        <h3>
            Symptoms of Infertility
        </h3>
        <ul>
            Abnormal sperm production which may be caused by genetic disorders, hormonal problems, health problems, infections and physical problems with testicles.
            Problems with delivery of sperm due to blockage of ducts that carry sperms, premature ejaculation, or injury to reproductive organs.
            Lifestyle and environmental factors such as smoking, depression, frequent exposure to heat, and exposure to pesticides.
        </ul>
        
        <p>
            Up to 15 percent of couples are infertile. This means they aren't able to conceive a child, even though theyâ€™ve had frequent, unprotected sexual intercourse for a year or longer. In over a third of these couples, male infertility plays a role.
            
            Male infertility is due to low sperm production, abnormal sperm function or blockages that prevent the delivery of sperm. Illnesses, injuries, chronic health problems, lifestyle choices and other factors can play a role in causing male infertility.Symptoms
            
            The main sign of male infertility is the inability to conceive a child. There may be no other obvious signs or symptoms. In some cases, however, an underlying problem such as an inherited disorder, a hormonal imbalance, dilated veins around the testicle or a condition that blocks the passage of sperm causes signs and symptoms.
            
            Although most men with male infertility do not notice symptoms other than the inability to conceive a child, signs and symptoms associated with male infertility include:
        </p>
        
        <ul>
            Problems with sexual function  for example, difficulty with ejaculation or small volumes of fluid ejaculated
            reduced sexual desire, or difficulty maintaining an erection (erectile dysfunction)
            Pain, swelling or a lump in the testicle area
            Recurrent respiratory infections
            Inability to smell
            Abnormal breast growth (gynecomastia)
            Decreased facial or body hair or other signs of a chromosomal or hormonal abnormality
            A lower than normal sperm count (fewer than 15 million sperm per milliliter of semen or a total sperm count of less than 39 million per ejaculate)
            See a urologist near you  if you have been unable to conceive a child after a year of regular, unprotected intercourse.
        </ul>
        
        <p>
            If youâ€™ve not been able to get a fertile female pregnant and youâ€™ve been having sexual intercourse for at least 12 months, and there is no contraception involved, this could be a sign of male infertility. Youâ€™ll need to visit alocal urologist  and get diagnosed. Male infertility can be treated though it depends on the root cause. Male infertility treatment ought to be administered by a local urology specialist near you.
        </p>
 </section>
    
</main>