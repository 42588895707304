<main class="container row mx-auto">
    <section class="p-5 col-lg-10 mx-auto">
            <h3 class="text-center">PROSTATE CANCER</h3> 
            <p>Introduction Without doubts, cancer ranks top among the most dreaded ailments of the 21st century. It is characterized by abnormal uncontrolled division of body cells; often leading to fatal outcomes.</p>
            <p>Literally, cancer can affect virually any structure of the body, but worthy of our attention is cancer of the prostate, which is the most common cancer among men worldwide.</p> 
            <p>The Prostate is a walnut-sized gland located between the urinary bladder above, the penis below, and the rectum behind. It secretes the prostatic fluid, a thin, milky substance, which makes up about 20% of the semen. The urethra, which conveys urine from the bladder to the penis, passes through the centre of the prostate </p>
            <h3>Symptoms </h3>
            
            <ul><li>Frequent passage of urine in the day (less than 2hrs in between) (Frequency)</li> <li><p>Frequent passage of urine at night (waking up more than twice to pass urine) (Nocturia)</p> </li><li><p>Inability to hold urine after being pressed (Urgency) </p></li>
                <li>Passing urine on one's self (if no toilet is unavailable) (urge incontinence)</li> <li>Feeling that some urine is left after voiding Weak stream of urine Straining to pass urine</li> <li>Intermittent stoppage of urine flow while voiding (Intermittency)</li> <li>Difficulty with commencing the act of voiding (hesitancy)</li> <li>Presence of blood in the urine (Hematuria)</li> <li>Recurrent infection in the urinary tract</li> </ul>
            
            What is prostate Cancer?
            
            
            <p>Prostate cancer can be viewed as the abnormal uncontrolled multiplication of the cells that make up the prostate gland. It is the most commonly diagnosed cancer among men beyond middle age (average of 50 years).</p> 
            <p>Its incidence rises rapidly beyond middle age: from as low as 14% among men greater than 50 years to as high as 80% among men greater than 70 years.</p><p> Worthy of note is the ability of prostate cancer to spread to organs immediately around it, and to those in distant sites in the body especially the bone, spinal cord, liver, lungs and the brain; this property is medically called metastasis. It is also important to be mindful of the ability of the cancer to shorten the lifespan of afflicted men dramatically, if left untreated.</p>
            <h3>Factors Increasing the Risk of Prostate Cancer </h3>
            <p>Unfortunately, no single causative agent is attributable to prostate cancer, although there are myriads of risk factors:</p> 
            <h3>Ageing:</h3> 
            <p>This is the strongest risk factor, both incidence and mortality rate increases exponentially at age beyond 50 years.</p> 
            <h3>Family and Genetic factors:</h3>
            <p>Evidence abounds that the risk of prostate cancer is higher if a man's first degree relative e.g. father, brother etc. has the cancer.</p>
            
            <h3>Race:</h3> 
            <p>some researchers have also found prostate cancer to be commoner among Americans and Africans, but to a lesser extent among the Orientals in China, Korea, Japan etc.</p>
            
            <h3>Diet:</h3> <p>Eating habits become highly relevant when the talk is about cancers; prostate inclusive. According to some studies, food high in dietary fat plays major role in the development of prostate cancer Multiple other weak risk factors have been identified, but research is ongoing to tease out actual risks from confounding factors.</p> 
            
            <h3>What are the Symptoms of Prostate Cancer?</h3>
            <p>In most cases, men with prostate cancer may not manifest any symptoms; in which case, it would have been an incidental finding on routine screening tests, most notably: Digital Rectal Examination (DRE) and a raised Prostate Specific Antigen (PSA) level. </p>
            
            <p>When Symptomatic, however, the symptoms would depend on the stage of the cancer: Cancer in the prostate The following symptoms may be present: </p> 
            
            
            <p>Cancer Spread to surrounding organs The following symptoms may be present:</p>
            <ol><li>Erectile dysfunction </li><li>Severe pain in the pelvis </li> <li>Bleeding from the anus </li> <li>Difficulty in passing stool due to blockage of intestine</li></ol> 
            
            <p>Cancer Spread to Distant Organs The following symptoms may be present:</p> 
            <li>Severe back pain due to spread to the back bone</li> 
            <li>Fracture of long bones due to spread to them</li> 
            <li>Paralysis of both legs, due to spread to spinal cord</li>
            <h3>What your local Urologist can do to diagnose prostate Cancer in McAllen, Texas</h3>
            
            <p>Digital Rectal Examination: </p>your doctor may introduce his gloved index finger into your anus to assess your prostate gland. If it feels hard and nodular, rather than firm and smooth, it is suspicious 
            <h3>Prostate Specific Antigen (PSA) Assay:
            </h3> 
            <p>PSA is a component of prostatic fluid. A value > 4mg/ml is suggestive of a prostate disease</p> 
            
            <h3>Protate biopsy:</h3> <p>using ultrasound as guide, your urologist can take a small sample of your prostate and send for analysis</p> 
            O<h3>ther tests:</h3> 
            <p>according to his discretion, your urologist may decide to run other tests such as:</p> 
            
            <ul><li>Urinalysis  </li>
            <li>Uroflowmetry studies </li></ul>
            <h3>Imaging tests:</h3> 
            <p>Abdomino-pelvic ultrasound scan, plain abdomino-pelvic X-ray, chest X-ray, Radionuclide bone scan, Computerized Tomography scan, Magnetic Resonance Imaging scan </p>
            
            <h3>What are the Treatment Options for Prostate Cancer?</h3>
            <p>There are several treatment modalities for prostate cancer. In most cases, these options are used in combination, rather than individual, to combat the disease. They Include:</p> 
            
            <h3>Active Surveillance:</h3> 
            <p>used in cases where patients have a very slow growing type of prostate cancer in low volumes within the prostate, and has no symptoms. It involves long term follow up of patient.</p>
            
            <h3>Prostatectomy:</h3> 
            <p>This is the surgical removal of the prostate gland. Radical prostatectomy involves removal of the entire prostate gland, and the tissue around it to which the cancer might have spread to e.g. seminal vesicle and surrounding lymph nodes. This may be sufficient to cure local disease</p> 
            
            <h3>Radiotherapy:</h3> 
            <p>this involves the use of ionizing radiation, delivered via a linear accelerator, to destroy cancer cells</p> 
            
            <h3>Hormonal Therapy:</h3> 
            <p>as cancer cells in the prostate requires male hormones called androgens to grow, hormonal therapy aims at depriving the cancer cells of these hormones; thus suppressing their growth â€¢ High Intensity </p>
            
            <h3>Focused Ultrasound (HIFU):</h3> 
            <p>this involves the use of specially designed ultrasound probe, to burn the cancer cells by inducing high temperature (90-100oC)</p>  
            
            <h3>Cryosurgery:</h3> 
            <p>this utilizes extremely cold temperatures to freeze and destroy cancer tissue in the prostate.</p> 
            <h3>Treatment options based on Stage </h3>
            <p>For Cancer in the prostate, Any of the methods discussed above or in combination.
            For Cancer that has Spread to surrounding organs, Hormonal therapy e.g. goserelin, buserelin, cyproterone acetate, diethylstibesterol etc Radiotherapy,Bilateral total orchidectomy (Surgical removal of the testicles).
            </p>
            <h3>Cancer Spread to Distant Organs</h3> 
            <ul><li>Hormonal therapy </li><li>Radiotherapy</li></ul> 
            
            <h3>Possible Complications of Prostate Cancer</h3>
            
            <ul><li>Seizures Hemiplegia (paralysis of one half of the body) </li>
            <li>Vertigo  Paraplegia (paralysis of both legs) </li>
            <li>Urinary retention </li>
            <li>Pyelonephritis </li>
            <li>Clot retention </li>
            <li>Lymphedema (due to obstruction lymph vessels by cancer cells)</li>
            <li>Anal stricture</li> 
            <li>Long bone fractures</li>
            </ul>
            
    </section>
</main>