<main class="row container mx-auto">
        <section class="col-lg-10 mx-auto p-5">
                <h3 class="text-center">
                        Kidney Stones
                    </h3>
                    <p>
                        Kidney stones (renal lithiasis, nephrolithiasis) are hard deposits made of minerals and salts that form inside your
                        kidneys.
                        Kidney stones have many causes and can affect any part of your urinary tract” from your kidneys to your bladder.
                        Often, stones form when the urine becomes concentrated, allowing minerals to crystallize and stick together.
                    </p>
                    
                    <button>Talk to a Doctor</button>
                    
                    <h3>
                        KIDNEY STONES
                    </h3>
                    <p>
                        Kidney stones are hard crystalline deposits that develop in the kidney and are passed to other parts of the urinary
                        tract. Smaller kidney stones can be passed through the urethra with urine. Kidney in the urethra may block urine and
                        cause extreme pain. Kidney stones vary in sizes and shapes with tiniest kidney stones being the size of grains of
                        sand and larger ones growing a few inches across. The largest kidney stone is usually the size of a golf ball,
                        though it can be even bigger in some cases. The kidney stone travel path starts in the kidney where they form and
                        move through the ureter into the bladder. Some kidneys stones are passed through the urethra with urine. The stages
                        of passing kidney stones can last an average of 45 days.
                        What are the 1st signs of kidney stones? The common 1st signs of kidney stones include severe pain in the belly,
                        back or side, vomiting, blood in urine, pain during urination, smelly urine and kidney pain sneezing.
                        The type of foods you eat may be a contributing factor to the development of kidney stones. So, what foods cause
                        kidney stones? Foods that may contribute to the formation of kidney stones include chocolate, beets, rhubarb,
                        spinach, tea, colas and nuts.
                        Below are some of the questions about kidney stones that people seek answers.
                    </p>
                    <h3>
                        Can a kidney stone pass overnight?
                    </h3>
                    <p>
                        The size of kidney stone usually determines the duration of the passing of kidney stones. Small kidneys tones can be
                        passed overnight while larger ones can take up 30 days.
                    </p>
                    <h3>
                        Can a kidney stone form overnight?
                    </h3>
                    <p>
                        Kidney stones do not form overnight. They form for months and even years before you start to experience symptoms.
                    </p>
                    <h3>
                        How can I flush out kidney stones?
                    </h3>
                    <p>
                        There are two common ways to flush out kidney stones -drinking a lot of water and medical therapy.
                    </p>
                    <h3>
                        Can multivitamins cause kidney stones?
                    </h3>
                    <p>
                        Multivitamins generally do not cause kidney stones. However, those who take high doses of vitamin C supplements
                        increase their risk of getting kidney stones.
                    </p>
                    <h3>
                        Can watermelon cause kidney stones?
                    </h3>
                    <p>
                        Watermelon does not cause kidney stones. It's very healthy for kidneys as it is rich in vitamins which improve the
                        functioning of kidneys.
                    </p>
                    <h3>
                        Does beer prevent kidney stones?
                    </h3>
                    <p>
                        Beer can increase oneâ€™s risk of developing kidney stones as it tends to impair the proper functioning of the
                        kidneys.
                        Sodium bicarbonate for kidney stones makes the urine less acidic to help get rid of kidney stones and prevent
                        reoccurrence.
                    </p>
                    <h3>
                        Does potassium citrate dissolve kidney stones?
                    </h3>
                    <p>
                        Potassium citrate is used to manage kidney stones as it inhibits the development of mineral crystalline materials
                        that end up becoming kidney stones. Besides, this compound helps to make urine less acidic.
                        Bean juice for kidney stones is rich in B vitamins and fiber that helps in cleaning kidneys and promotes better
                        functioning of the urinary tract.
                    </p>
                    <h3>
                        Can kidney stone pain be mild?
                    </h3>
                    <p>
                        Kidney stone pain occurs due to obstruction of the urinary tract, in which urine is blocked. Typically, the pain can
                        range from mild pain to intense pain that requires urgent medical attention. Sometimes, you can experience kidney
                        stone pain suddenly gone, but it usually returns.
                    </p>
                    <h3>
                        Can kidney stones make you constipated?
                    </h3>
                    <p>
                        Kidney stones cause dehydration which can cause constipation.
                        Below are kidney stone pictures
                    </p>
                    
                    
                    
                    
                    
                    <p>
                        Kidney stone treatment is done based on the size and type of stone. Smaller kidney stones may not require invasive
                        treatment and can easily be passed by drinking a lot of water and medical therapy. Larger stones that cause severe
                        discomfort require more extensive treatment such as surgery and use of sound waves.
                        Severe kidney stones may require you to go to the local hospital in McAllen, Texas
                        If you are in severe pain or experience the signs mentioned above, you should consult a local urologist immediately.
                        Make an appointment with your South Texas urologist if you have any signs and symptoms that worry you.
                    </p>
                    
                    Seek immediate medical attention if you experience:
                    
                    <ul>
                        <li> Pain so severe that you can't sit still or find a comfortable position</li>
                        <li> Pain accompanied by nausea and vomiting</li>
                        <li> Pain accompanied by fever and chills</li>
                        <li> Blood in your urine</li>
                        <li> Difficulty passing urine</li>
                    </ul>
        </section>
</main>