import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kidney-stones',
  templateUrl: './kidney-stones.component.html',
  styleUrls: ['./kidney-stones.component.css']
})
export class KidneyStonesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
