<main class="row container mx-auto">
        <section class="col-lg-10 mx-auto p-5">
        <h3 class="text-center">No Scapel Vasectomies</h3> 
        <p>During a standard vasectomy, two incisions are made in the scrotum to allow the surgeon to reach each of the man's vas deferens (the two tubes that transmit sperm into the semen).
        </p>
        <p>During a no-scalpel vasectomy (also referred to as a keyhole vasectomy), no incisions are made. Instead, the surgeon uses a hemostat (locking forceps with a sharp tip) to puncture through the skin of the scrotal sac. Then, the skin is gently spread only until both vas deferens can be visualized. Because the puncture site is so small and is just through the skin, stitches are usually not required and healing time is quick.</p>
        <p>As compared to a traditional vasectomy, the no-scalpel approach is a less invasive and quicker procedure (total time including prep and anesthesia is approximately 15 to 20 minutes).
        </p>

        </section>
        
</main>