import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';
import { ServicesComponent } from './services/services.component';
import { SocialmediaComponent } from './socialmedia/socialmedia.component';
import { AboutComponent } from './about/about.component';
import { ProstateCancerComponent } from './prostate-cancer/prostate-cancer.component';
import { KidneyStonesComponent } from './kidney-stones/kidney-stones.component';
import { UrinaryIncontinenceComponent } from './urinary-incontinence/urinary-incontinence.component';
import { ErectileDysfunctionComponent } from './erectile-dysfunction/erectile-dysfunction.component';
import { MaleInfertilityComponent } from './male-infertility/male-infertility.component';
import { NoScapelVasectomyComponent } from './no-scapel-vasectomy/no-scapel-vasectomy.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ContactComponent,
    ServicesComponent,
    SocialmediaComponent,
    AboutComponent,
    ProstateCancerComponent,
    KidneyStonesComponent,
    UrinaryIncontinenceComponent,
    ErectileDysfunctionComponent,
    MaleInfertilityComponent,
    NoScapelVasectomyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
