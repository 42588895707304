<main class="py-5 container">

    
    <section class="row">
        <div class="col-lg-4 align-self-center"><img src="../assets/patient_consulting.jpg" alt="" width="100%"></div>
        <div class="col-lg-8">
                <h3>What is Prostate cancer?</h3>
                <p>
                
                        The prostate is a small walnut shaped gland in the pelvis of men. It is located next to the bladder and can be examined by getting a digital rectal exam. Prostate cancer is a form of cancer that develops in the prostate gland. It is the second-leading cause of cancer deaths for men in the U.S. About 1 in 9 men will be diagnosed with prostate cancer in their lifetime.
                        
                        Growths in the prostate can be benign (not cancer) or malignant (cancer).
                        
                        Benign growths (like benign prostatic hyperplasia (BPH):
                        
                        Are rarely a threat to life
                        Don't invade the tissues around them
                        Don't spread to other parts of the body
                        Can be removed and can grow back very slowly (but usually don't grow back)
                        Malignant growths (prostate cancer):
                        
                        May sometimes be a threat to life
                        Can spread to nearby organs and tissues (such as the bladder or rectum)
                        Can spread (metastasize) to other parts of the body (like lymph nodes or bone) 
                        Often can be removed but sometimes grow back
                        
                        Prostate cancer cells can spread by breaking away from a prostate tumor. They can travel through blood vessels or lymph nodes to reach other parts of the body. After spreading, cancer cells may attach to other tissues and grow to form new tumors, causing damage where they land.
                        
                        Symptoms:
                        In its early stages, prostate cancer usually has no symptoms. When symptoms do occur, they can be like those of an enlarged prostate or BPH. Prostate cancer can also cause symptoms unrelated to BPH.</p>
                
        </div>
    </section>
    

   
        
</main>
