<main class="continer mx-auto row">
    <section class="mx-auto col-lg-10 p-5">
            <h3 class="text-center">Urinary Incontinence</h3>
            <p>
                It's important to determine the type of urinary incontinence that you have, and your symptoms often tell your doctor which type you have. That information will guide treatment decisions.
                We like to start with a thorough history and physical exam. You may then be asked to do a simple maneuver that can demonstrate incontinence, such as coughing
            .</p>
            
         
            
            
            
            
            <p>
                It's important to determine the type of urinary incontinence that you have, and your symptoms often tell your doctor which type you have. That information will guide treatment decisions.
                
                We like to start with a thorough history and physical exam. You may then be asked to do a simple maneuver that can demonstrate incontinence, such as coughing.
                
                After that, possibilities could be:
            </p>
            
            <h3>
                Urinalysis.
            </h3> 
            <p>
                A sample of your urine is checked for signs of infection, traces of blood or other abnormalities.
                
                Bladder diary. For several days you record how much you drink, when you urinate, the amount of urine you produce, whether you had an urge to urinate and the number of incontinence episodes.
                
                Post-void residual measurement. You're asked to urinate (void) into a container that measures urine output. Then we check the amount of leftover urine in your bladder using a catheter or ultrasound test. A large amount of leftover urine in your bladder may mean that you have an obstruction in your urinary tract or a problem with your bladder nerves or muscles.
                
                If further information is needed, we may recommend more-involved tests, such as urodynamic testing and pelvic ultrasound. These tests are usually done if you're considering surgery.
            </p>

    </section>
        
</main>