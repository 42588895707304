 

<header class="">
  <nav class="navbar navbar-expand-lg navbar-light bg-light static-top">
    <div class="container">
      <a class="navbar-brand" routerLink="/home">
           <div>
             <div class="row">
               <div class="col-4"><img class="logo_img" src="../assets/yengeh_urology_log.svg" alt=""></div>
               <div class="col-8 row d-flex align-self-center logo_text_wrapper">
                  <h1 class="col-12 yengeh"><span>Yengeh</span></h1>
                  <h1 class="col-12"><span class="urology">Urology</span></h1>
               </div>
             </div>
           </div>
          </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
      <div class="collapse navbar-collapse" id="navbarResponsive">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item ">
            <a class="nav-link" routerLink="/home" routerLinkActive="active nav_select_state">Home
                  <span class="sr-only">(current)</span>
                </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/about" routerLinkActive="active active nav_select_state">About</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/services" routerLinkActive="active active nav_select_state">Services</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/socialmedia" routerLinkActive="active active nav_select_state">Social Media</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/contact" routerLinkActive="active active nav_select_state">Contact</a>
          </li>
        </ul>
      </div>
    </div>
  </nav> 
</header>
<router-outlet></router-outlet>
<footer class=" w3-padding-64 w3-center w3-text-white">  
    <div class="w3-xlarge w3-padding-32">
      <i class="fa fa-facebook-official w3-hover-opacity"></i>
      <i class="fa fa-instagram w3-hover-opacity"></i>
      <i class="fa fa-twitter w3-hover-opacity"></i>
      <i class="fa fa-linkedin w3-hover-opacity"></i>
   </div>
   <div class="p-4">
     <img class="footer_logo_img" src="../assets/yengeh_urology_log.svg" alt="">
     <p class="p-3"> <span>&#169;</span> Yengeh Urology all rights reserved</p>
    </div>
     
   </footer>
