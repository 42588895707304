import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-male-infertility',
  templateUrl: './male-infertility.component.html',
  styleUrls: ['./male-infertility.component.css']
})
export class MaleInfertilityComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
