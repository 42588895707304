<main class="">
    
    <div class="hero_sec"></div>
    
        <section class="jumbotron row mb-0 mx-auto">
        <article class="text-center col-lg-8 mx-auto"><h3>Welcome to Yengeh Urology.</h3><p>
            We are the resource for all your urologic needs in the Rio Grande Valley.
            We offer patient and provider education and referral for urologic services.
            Our goal is to provide you with the information you need to make the best medical decisions for you and your family. 
            Our believe is that when patients are well informed, they are better partners in the management of their urologic health issues and overall more satisfied with their care.</p></article>
        
          </section>

          <section class=" w3-red mx-auto p-5 services_section">
            <div class="container row mx-auto">
          
            
              <div class="col-lg-4">
                <div>
                    <a  class="w3-hover-opacity" routerLink="/prostate-cancer">
                    <div>
                      <h2>Prostate Cancer</h2>
                      <p>Without doubts, “Cancer” ranks top among the most dreaded ailments of the 21st century.</p>
                    </div>
                    <div><button class="btn-round btn-lg badge-pill">Read More</button></div>
                    <div><img src="../assets/prostate_cancer.svg" alt=""></div>
                  </a>
                </div>
                
              </div>
            
              <div class="col-lg-4">
                  <div>
                      <a  class="w3-hover-opacity" routerLink="/kidney-stones">
                      <div>
                        <h2>Kidney Stones</h2>
                        <p>Kidney stones (renal lithiasis, nephrolithiasis) are hard deposits made of minerals and salts</p>
                      </div>
                      <div><button class="btn-round btn-lg badge-pill">Read More</button></div>

                      <div><img src="../assets/kidney_stones.svg" width="100%" alt=""></div>
                    </a>
                  </div>
                  
                </div>
                <div class="col-lg-4">
                    <div>
                        <a  class="w3-hover-opacity" routerLink="/urinary-incontinence">
                        <div>
                          <h2>Urinary Incontinence</h2>
                          <p>It’s important to determine the type of urinary incontinence that you have,</p>
                        </div>
                        <div><button class="btn-round btn-lg badge-pill">Read More</button></div>

                        <div><img src="../assets/male_incontinence.svg" width="100%" alt=""></div>
                      </a>
                    </div>
                    
                  </div>
                  <div class="col-lg-4">
                      <div>
                          <a  class="w3-hover-opacity" routerLink="/erectile-dysfunction">
                          <div>
                            <h2>Erectile Disfunction</h2>
                            <p>Erectile dysfunction is a condition in which a man is unable to get and keep an erection</p>
                          </div>
                          <div><button class="btn-round btn-lg badge-pill">Read More</button></div>

                          <div><img src="../assets/erectile_disfunction.svg" alt=""></div>
                        </a>
                      </div>
                      
                    </div>
                    <div class="col-lg-4">
                        <div>
                            <a  class="w3-hover-opacity" routerLink="/male-infertility">
                            <div>
                              <h2>Male Infertility</h2>
                              <p>Up to 15 percent of couples are infertile.</p>
                            </div>
                            <div><button class="btn-round btn-lg badge-pill">Read More</button></div>

                            <div><img src="../assets/male_infertility.svg" alt=""></div>
                          </a>
                        </div>
                        
                      </div>
                      <div class="col-lg-4">
                          <div>
                              <a  class="w3-hover-opacity" routerLink="/no-scapel-vasectomy">
                              <div>
                                <h2>No Scapel Vasectomy</h2>
                                <p>During a standard vasectomy, two incisions are made in the scrotum to allow</p>
                              </div>
                              <div><button class="btn-round btn-lg badge-pill">Read More</button></div>

                              <div><img src="../assets/vasectomy.svg" alt=""></div>
                              </a>
                          </div>
                          
                        </div>
            </div>
          </section>

          <section class="p-5">
              <!-- First Grid -->
<div class="container">
    <div class="row">
      <div class="col-lg-8">
        <h1>Meet Dr. Adamu</h1>
        <h5 class="w3-padding-24">Dr. Adamu is one of the 3 Best rated urologists in McAllen,</h5>
  
        <p class="w3-text-grey">Texas. Best rated urologists are handpicked. They face a rigorous 
          50 point inspection, 
          which includes customer reviews, history, complaints, ratings, satisfaction, trust, cost, 
          and general excellence. You deserve the best! Dr. Adamu is a board certified urologist who 
          is fluent in English, French, Spanish. He specializes in overactive bladder & urinary 
          incontinence, prostate cancer, kidney stones and erectile dysfunction. He performs 
          elective surgeries and in office procedures and is trained in robotic surgery as well.</p>
      </div>
  
      <div class="col-lg-4">
        <img src="../assets/dr_desmond_adamu_hospital.jpeg" alt="" width="100%">
      </div>
    </div>
  </div>
          </section>

      <section class="container mx-auto  d-flex row p-5">
        <div class="col-lg-4"><img src="../assets/insurance.jpg" alt="" width="100%"></div>
        <div class="col-lg-8 align-self-center">
          <h3>Most Insurance Plans Accepted</h3>
          <p>At Yengeh Urology, we accept most major insurance plans. 
            Please bring your insurance card to every appointment and we can verify 
            your coverage. We require up-to-date and accurate insurance information to file 
            your claim. 
          </p>
            
               

        </div>
      </section>    
</main>