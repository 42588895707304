import { NoScapelVasectomyComponent } from './no-scapel-vasectomy/no-scapel-vasectomy.component';
import { UrinaryIncontinenceComponent } from './urinary-incontinence/urinary-incontinence.component';
import { ErectileDysfunctionComponent } from './erectile-dysfunction/erectile-dysfunction.component';
import { MaleInfertilityComponent } from './male-infertility/male-infertility.component';
import { ProstateCancerComponent } from './prostate-cancer/prostate-cancer.component';
import { KidneyStonesComponent } from './kidney-stones/kidney-stones.component';
import { SocialmediaComponent } from './socialmedia/socialmedia.component';
import { AboutComponent } from './about/about.component';
import { ServicesComponent } from './services/services.component';
import { ContactComponent } from './contact/contact.component';
import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'about', component: AboutComponent },
  { path: 'services', component: ServicesComponent },
  { path: 'socialmedia', component: SocialmediaComponent },
  { path: 'kidney-stones', component: KidneyStonesComponent },
  { path: 'prostate-cancer', component: ProstateCancerComponent },
  { path: 'male-infertility', component: MaleInfertilityComponent },
  { path: 'erectile-dysfunction', component: ErectileDysfunctionComponent },
  { path: 'urinary-incontinence', component: UrinaryIncontinenceComponent},
  { path: 'no-scapel-vasectomy', component: NoScapelVasectomyComponent },
  { path: '**', component: HomeComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
